define(['app'], (app) => {
	var yotiVerificationCancel = () => {
		const component = {};

		component.init = (element) => {
			component.element = element;
			component.closeModal();
		};

		component.closeModal = () => {
			const event = new CustomEvent('yotiAgeVerificationCloseModal')
			window.parent.document.dispatchEvent(event);
		}

		return component;
	};

	return yotiVerificationCancel;
});